.container-cargando {
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: fixed !important;

    z-index: 214748364 !important;
    -webkit-transition: width 2s;
    cursor: progress;
}

.container-gif {
    min-height: 100vh;
    min-width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);
}

.oculto {
    display: none;
}

.gif {
    background-color: transparent;
    opacity: 1;
}
