.container-tracking {
  height: 100vh !important;
  min-height: 100vh !important;
  width: 100% !important;
  min-width: 100% !important;
}

.container-mapa {
  height: calc(100vh - 83px - 48px) !important;
  min-height: calc(100vh - 83px - 48px) !important;
  width: 100% !important;
  min-width: 100% !important;
}

.container-mapa-time {
  height: calc(100vh - 48px) !important;
  min-height: calc(100vh - 48px) !important;
  width: 100% !important;
  min-width: 100% !important;
}

@media (min-width: 0px) and (max-width: 600px) {
  .container-mapa {
    height: calc(100vh - 83px - 58.59px) !important;
    min-height: calc(100vh - 83px - 58.59px) !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .container-mapa-time {
    height: calc(100vh - 58.59px) !important;
    min-height: calc(100vh - 58.59px) !important;
    width: 100% !important;
    min-width: 100% !important;
  }
}

.img-customer {
  height: 25px;
}

.img-logo-rayo {
  height: 22px;
}

@media (min-width: 0px) and (max-width: 600px) {
  .img-customer {
    height: 20px;
  }

  .img-logo-rayo {
    height: 17px;
  }

  .container-mapa-time .col-md-4,
  .container-mapa-time .col-md-8,
  .container-mapa .col-md-4,
  .container-mapa .col-md-8 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.container-informacion {
  position: absolute;
  bottom: 30px;
  margin-left: 100px;
  margin-top: 100px;
  width: calc(100% - 200px);
  background-color: #f2f2f2;
}

.container-hora {
  position: absolute;
  bottom: calc(100px + 3.5rem);
  right: 100px;
  background-color: #f2f2f2;
}

.img-tracking {
  height: 60px;
  width: 60px;
  border-radius: 5px;
}

.estado-track {
  top: 75px;
  left: 70px;
}

.container-hora p,
.container-informacion p {
  font-family: "Roboto-Regular";
  font-size: 12px;
  color: #545454;
  margin-bottom: 0px;
  line-height: 1.4;
}

.border-container-informacion {
  border-color: #cccccc !important;
}

.container-gt {
  background-color: #e3e3e3;
  border-radius: 5px;
  padding: 0.8rem;
  height: 100px;
  overflow: auto;
  font-family: "Roboto-Regular";
  font-size: 12px;
}

.txt-bold {
  font-family: "Exo2-Bold" !important;
  text-transform: uppercase;
}

.container-estimado {
  max-width: 300px;
  width: 100%;
}

@media (min-width: 0px) and (max-width: 992px) {
  .container-informacion {
    position: absolute;
    bottom: 15px;
    margin-left: 15px;
    margin-top: 15px;
    width: calc(100% - 30px);
  }

  .container-hora {
    position: absolute;
    bottom: calc(188px + 3rem);
    right: 30px;
    background-color: #f2f2f2;
  }

  .img-tracking {
    height: 40px;
    width: 40px;
  }

  .estado-track {
    top: 34px;
    left: 49px;
  }

  .tracking-rayo {
    margin-bottom: 1rem !important;
    margin-top: 0.5rem !important;
  }

  .container-gt {
    margin-bottom: 0.5rem !important;
  }
}

.MuiSvgIcon-root.text-white {
  color: #ffffff !important;
}

.boton-whatsapp {
  position: absolute !important;
  top: 50px;
  left: -25px;
}
