.home-screen {
  height: calc(100vh - 64px) !important;
  min-height: calc(100vh - 64px) !important;
}

.img-rayo {
  border-radius: 5px;
}

.map {
  height: calc(100vh - 64px);
  border: none !important;
  width: 100%;
}

.cargando-paneles {
  height: calc(100vh - 64px - 99px - 40px);
}

.cargando-paneles-rayo {
  height: calc(100vh - 64px - 99px) !important;
  max-height: calc(100vh - 64px - 99px) !important;
  overflow: auto !important;
}

.container-info-gt {
  height: 82px !important;
  overflow: auto !important;
}

.container-info-gt::-webkit-scrollbar {
  width: 0px !important;
}

.paper-total {
  max-height: 100vh;
  min-height: 100vh;
  overflow: auto !important;
  background-color: #fff !important;
}

.paper {
  max-height: calc(100vh - 64px - 99px);
  min-height: calc(100vh - 64px - 99px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-perfil {
  height: calc(100vh - 64px - 86px);
  overflow: auto !important;
}

.paper-rayos-tasks {
  height: calc(100vh - 64px - 89px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-tarea {
  height: calc(100vh - 64px - 219px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-mini {
  height: calc(100vh - 64px - 99px - 54px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-editar {
  height: calc(100vh - 64px - 51px - 60px - 56px);
  overflow: auto !important;
  background-color: #fff !important;
}

.paper-middle {
  height: calc(100vh - 38px - 64px - 54px);
  overflow: auto !important;
  background-color: #fff !important;
}

.hide-navbar {
  height: 64px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  display: flex;
  z-index: 1300;
  width: 100%;
}

.plomo {
  background-color: #fff !important;
}

.div-black {
  background-color: #4545f4;
}

.div-gray {
  background-color: #545454;
}

input.input-sup,
input.input-sup:-webkit-autofill,
input.input-sup:-webkit-autofill:hover,
input.input-sup:-webkit-autofill:focus,
input.input-sup:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #e5e5e5 inset !important;
  background-color: #e5e5e5 !important;
  border: none;
  color: #343434;
  font-size: 25px;
}

.input-sup:focus,
.input-sup:focus {
  outline: none;
}

.button-inf {
  color: #f2f2f2 !important;
}

.button-inf:hover {
  background-color: #242422 !important;
}

.button-inf:active {
  background-color: #bf0811 !important;
}

.card-task {
  width: 90%;
}

.card-rayo {
  width: 95% !important;
  box-shadow: none !important;
}

.button-state {
  color: #f2f2f2 !important;
  border-radius: 10% !important;
  font-size: 12px !important;
}

.button-number-task:disabled,
.button-number-task[disabled],
.button-number-task {
  height: 30px;
  width: 30px;
  font-family: "Exo-Bold" !important;
  margin-bottom: 4px !important;
}

.button-state:disabled,
.button-state[disabled] {
  color: #f2f2f2 !important;
  border-radius: 0.4rem !important;
  font-size: 12px !important;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
}

.MuiCardContent-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.MuiCheckbox-root {
  padding: 0.5rem !important;
}

.carta-tarea {
  border-radius: 5px;
  height: 140px;
  overflow: auto !important;
}

.orden-name {
  height: 40px;
  z-index: 2;
}

.carta-orden {
  border-radius: 5px;
  border: 2px solid #9999ff;
}

.carta-orden-red {
  border-radius: 5px;
  border: 2px solid rgb(220, 9, 20);
}

.carta-orden-green {
  border-radius: 5px;
  border: 2px solid #28bd27;
}

.carta-orden-estado {
  border-radius: 8px;
  border: 2px solid #9999ff;
  background-color: #fff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-red {
  border-radius: 8px;
  border: 2px solid rgb(220, 9, 20);
  background-color: #fff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-green {
  border-radius: 8px;
  border: 2px solid #28bd27;
  background-color: #fff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-historial {
  border-radius: 8px;
  border: 2px solid #9999ff;
  background-color: #ffffff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 12px;
  width: 180px;
}

.carta-orden-estado-saltada {
  border-radius: 8px;
  border: 2px solid #707070;
  background-color: #ffffff;
  position: absolute;
  font-family: "Roboto-Regular";
  top: -15px;
  left: 0px;
  width: 100%;
}

.carta-orden-estado-gt {
  background-color: #ffffff;
  font-family: "Roboto-Regular";
  border-radius: 5px;
  height: 30px !important;
}

.btn-rayo.MuiButton-text {
  padding: 6px !important;
}

.min-txt-carta {
  font-size: 6px;
  line-height: 1.2;
}

.sub-txt-carta {
  color: #545454;
  font-size: 11px;
  line-height: 1.2;
}

.txt-carta {
  font-size: 11px !important;
  line-height: 1.2 !important;
}

.txt-carta-name {
  font-size: 12px;
  line-height: 1.2;
}

.txt-carta-medium {
  font-size: 10px;
  line-height: 1.2;
}

.txt-carta-small {
  font-size: 9px;
  line-height: 1.2;
}

.txt-rayo-name {
  font-size: 13px;
  line-height: 1.2;
}

.rotar-icono {
  transform: rotate(90deg);
}

.rotar-icono-45 {
  transform: rotate(45deg);
}

.rotar-icono-180 {
  transform: rotate(180deg);
}

@media (min-width: 600px) {
  .MuiTab-root {
    padding: 6px 0px !important;
    min-width: auto !important;
  }
}

.tab-task {
  width: 50% !important;
  font-family: "Exo2-Regular" !important;
  color: #ffffff !important;
  background-color: #a6a6a6 !important;
}

.tab-task.Mui-selected {
  width: 50% !important;
  font-family: "Exo2-Bold" !important;
  color: #5c5c57 !important;
  background-color: #efefef !important;
}

.tab-rayos {
  /* width: 33.33333333333333333333333% !important; */
  width: 50% !important;
  font-family: "Exo2-Regular" !important;
  color: #ffffff !important;
  background-color: #a6a6a6 !important;
}

.tab-rayos.Mui-selected {
  /* width: 33.33333333333333333333333% !important; */
  width: 50% !important;
  font-family: "Exo2-Bold" !important;
  color: #5c5c57 !important;
  background-color: #efefef !important;
}

.tab-rayos-home {
  width: 33.33333333333333333333333% !important;
  /* width: 50% !important; */
  font-family: "Exo2-Regular" !important;
  color: #ffffff !important;
  background-color: #a6a6a6 !important;
}

.tab-rayos-home.Mui-selected {
  width: 33.33333333333333333333333% !important;
  /* width: 50% !important; */
  font-family: "Exo2-Bold" !important;
  color: #5c5c57 !important;
  background-color: #efefef !important;
}

.tab-detalle {
  width: 50% !important;
  font-family: "Roboto-Regular" !important;
  color: #ffffff !important;
  background-color: #9999ff !important;
}

.tab-detalle.MuiTab-textColorInherit.Mui-selected {
  background-color: #1a11d1 !important;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.task-type-line {
  height: 100%;
  border-left: 2px solid #9999ff;
  margin-left: 7px;
}

.task-type-dot {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  position: absolute;
  font-family: "Roboto-Regular" !important;
  color: #ffffff !important;
  font-size: 12px;
}

.task-type-little-dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: #9999ff;
  position: absolute;
  bottom: 0px;
  left: 4px;
}

.dot-orden-list {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  z-index: 300;
  position: absolute;
}

.borde-orden-list {
  position: absolute;
  height: 150px;
  left: 7px;
  top: -110px;
}

.borde-orden-list-historial {
  position: absolute;
  height: 60px;
  border-left: 2px solid #9999ff;
  left: 7px;
  top: -40px;
}

.borde-orden-list-1 {
  position: absolute;
  height: 70%;
  left: 7px;
  top: -38px;
}

.borde-orden-list-x {
  position: absolute;
  border-top: 2px solid #9999ff;
  top: -38px;
  width: 350%;
  bottom: 75%;
  left: 7px;
}

.alto-dark {
  height: 51px;
}

.alto-grey {
  height: 48px;
}

.bottom-fix {
  width: 100%;
  bottom: 0px;
  padding-top: 11px;
  padding-bottom: 11px;
  height: 54px;
  background-color: #fff;
}

.MuiTableCell-root {
  padding: 14px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiTableCell-root.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.MuiTableCell-root.mini-table-th {
  padding: 2px !important;
  font-size: 11px;
  background-color: #9f9f9f;
}

.MuiTableCell-root.mini-table-td {
  font-size: 11px;
  text-align: center !important;
  background-color: #cfcdcd;
}

.mini-table-thead tr {
  height: auto !important;
}

table.mini-table {
  border-collapse: separate !important;
  border-spacing: 0 0px !important;
}

.dot-tipo-tarea {
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.MuiInput-underline:after {
  border: none !important;
}

.MuiInputBase-input {
  border-radius: 10px !important;
  font-size: 0.85rem !important;
}

input[type="datetime-local"].MuiInputBase-input {
  padding: 10px 5px 0px !important;
}

.MuiFormControl-root.MuiTextField-root.form-control {
  border-radius: 0.25rem;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.opc-drop {
  height: 37px;
  width: 200px;
  background-color: #fff;
  font-family: "Roboto-Regular" !important;
  border-radius: 2px;
}

.opc-drop-gt {
  height: 35px;
  width: 220px;
  background-color: #fff;
  font-family: "Roboto-Regular" !important;
  border-radius: 2px;
}

.opc-drop-gt:hover,
.opc-drop:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.dropdown-menu {
  border: none !important;
  background-color: transparent !important;
}

.border-bottom-0 {
  border-top: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  border-bottom: none !important;
}

.select-crear > .MuiFilledInput-input {
  padding: 10px;
}

.modal-body > .MuiFormControl-root > .select-crear > .MuiIconButton-edgeEnd,
.modal-body
  > row
  > col-8
  > .MuiFormControl-root
  > .select-crear
  > .MuiIconButton-edgeEnd {
  margin-right: 0px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.modal-body > .MuiFormControl-root > .select-crear,
.select-crear.select-modal {
  background-color: transparent !important;
  border-radius: 4px !important;
  border: 2px solid #1a11d1 !important;
  height: 40px;
}

.select-crear.select-modal.e-tarea {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border: 2px solid #1a11d1 !important;
  height: 34px;
}

.MuiPopover-root {
  z-index: 1301 !important;
}
/* .MuiPopover-root {
    margin-top: 0px !important;
    left: -16px !important;
} */
div.form-control.txt-modal,
input.form-control.txt-modal {
  border: 2px solid #1a11d1 !important;
  height: 40px;
  border-radius: 4px !important;
  background-color: transparent !important;
}

.vista-lista > .MuiTableHead-root > tr > th {
  font-size: 12px !important;
  line-height: 1.2 !important;
}

.vista-lista > .MuiTableBody-root > tr > td {
  font-size: 12px !important;
  line-height: 1.2 !important;
}

.col-65 {
  flex: 0 0 58.3333333333333%;
  max-width: 58.3333333333333%;
  position: relative;
}

.col-05 {
  flex: 0 0 4.1666666666666%;
  max-width: 4.1666666666666%;
  position: relative;
}

.address-task-foot {
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.panel-detalle {
  width: 380px !important;
  min-width: 380px !important;
  min-height: 100vh !important;
  background-color: #fff !important;
}

.autocomplete-rayo .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.autocomplete-rayo
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 8px 4px 11px !important;
}

.autocomplete-layout-rayos {
  border: none !important;
  height: 30px !important;
  border-radius: 4px;
  min-width: 152px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores.autocomplete-layout-clientes {
  border: none !important;
  height: 30px !important;
  border-radius: 4px;
  min-width: 160px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores {
  background-color: #4545f4;
  border: none !important;
  height: 30px !important;
  border-radius: 4px;
  min-width: 180px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores input::placeholder,
.autocomplete-layout-rayos input::placeholder {
  color: #ffffff !important;
  opacity: 1 !important;
  font-family: "Roboto-Regular", FontAwesome !important;
}

.autocomplete-layout-proveedores .MuiOutlinedInput-root fieldset,
.autocomplete-layout-rayos .MuiOutlinedInput-root fieldset {
  border: none !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.autocomplete-layout-rayos
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
  padding-left: 5px !important;
  padding-right: 45px !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input,
.autocomplete-layout-rayos
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 3px 4px 5px !important;
  font-family: "Roboto-Regular" !important;
  color: #ffffff !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-popupIndicator
  .MuiSvgIcon-root,
.autocomplete-layout-rayos .MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  fill: #ffffff !important;
}

.autocomplete-layout-proveedores .MuiAutocomplete-popupIndicator,
.autocomplete-layout-rayos .MuiAutocomplete-popupIndicator {
  /* background-color: #1a11d1 !important; */
  margin-right: -10px !important;
  border-radius: 4px !important;
  height: 30px !important;
  top: -2px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.autocomplete-layout-proveedores
  .MuiAutocomplete-clearIndicatorDirty
  .MuiSvgIcon-root,
.autocomplete-layout-rayos
  .MuiAutocomplete-clearIndicatorDirty
  .MuiSvgIcon-root {
  fill: #ffffff !important;
}

.autocomplete-layout-proveedores .MuiAutocomplete-clearIndicatorDirty,
.autocomplete-layout-rayos .MuiAutocomplete-clearIndicatorDirty {
  top: -2px !important;
}

.end-start-icon .MuiButton-startIcon {
  position: absolute !important;
  left: 10px !important;
}

.end-start-icon .MuiButton-endIcon {
  position: absolute !important;
  right: 15px !important;
}

.task-form .react-tel-input .form-control:-webkit-autofill,
.task-form .react-tel-input .form-control:-webkit-autofill:focus,
.task-form .react-tel-input .form-control:-webkit-autofill:active,
.task-form .react-tel-input .form-control:-webkit-autofill:hover,
.task-form .react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
  background: #dad8d8 !important;
  -webkit-box-shadow: 0 0 0 30px #dad8d8 inset !important;
  background-color: #dad8d8 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 1rem !important;
  font-family: "Roboto-Regular" !important;
}

.task-form .react-tel-input .flag-dropdown {
  border: none !important;
  background-color: #dad8d8 !important;
  border-radius: 10px 0 0 10px !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.task-form .react-tel-input .selected-flag {
  border: none !important;
  border-radius: 10px 0 0 10px !important;
  background-color: #dad8d8 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.react-tel-input .form-control:-webkit-autofill,
.react-tel-input .form-control:-webkit-autofill:focus,
.react-tel-input .form-control:-webkit-autofill:active,
.react-tel-input .form-control:-webkit-autofill:hover,
.react-tel-input .form-control {
  width: 100% !important;
  border: none !important;
  background: #f2f2f2 !important;
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  background-color: #f2f2f2 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 1rem !important;
  font-family: "Roboto-Regular" !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  background-color: #f2f2f2 !important;
  border-radius: 10px 0 0 10px !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.react-tel-input .selected-flag {
  border: none !important;
  border-radius: 10px 0 0 10px !important;
  background-color: #f2f2f2 !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.react-tel-input .country-list {
  width: 315px !important;
  margin-top: 0px !important;
  font-family: "Roboto-Regular" !important;
}

.react-tel-input .country-list .flag {
  margin-right: 14px !important;
}

.py-05 {
  padding-bottom: 0.15rem !important;
}
.py-05 {
  padding-top: 0.15rem !important;
}

.px-05 {
  padding-left: 0.17rem !important;
}
.px-05 {
  padding-right: 0.17rem !important;
}
.f-right {
  right: 0px !important;
  margin-right: 0.17rem !important;
}

.scuare-light-blue {
  background-color: #9999ff;
  height: 30px;
  width: 30px;
  border-radius: 5px;
}
.checkbox-rayo > .MuiTypography-body1 {
  font-size: 9px !important;
  color: #545454;
  line-height: 1.2;
}

.letra-task {
  background-color: #1a11d1;
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  bottom: -6px;
  left: -6px;
  color: #ffffff;
  font-family: "Exo2-Bold";
  border: 2px solid #ffffff;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  display: none !important;
}

#content-mapa {
  background-color: #ffffff;
  padding: 0.6rem;
  border-radius: 10px;
  min-width: 210px;
}
.my-div-icon {
  height: 10px;
  width: 10px;
  background-color: #1a11d1;
}

.circle {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background-color: #1a11d1;
}

.circle-test {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 14px;
}

.circle-test,
.circle p {
  margin-bottom: 3px;
  font-size: 11pt;
  color: #ffffff;
  font-family: "Exo2-Bold";
  z-index: 500;
}

.triangle {
  position: absolute;
  top: 16px;
  width: 0;
  height: 0;
  border-radius: 20px;
  border-style: solid;
  border-width: 28px 14px 0 14px;
  border-color: #1a11d1 transparent transparent transparent;
  z-index: 0;
}

.triangle-test {
  position: absolute;
  top: 16px;
  width: 0;
  height: 0;
  border-radius: 20px;
  border-style: solid;
  border-width: 28px 14px 0 14px;
  z-index: 0;
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

#content-mapa {
  background-color: #ffffff;
  padding: 0.6rem;
  border-radius: 10px;
  min-width: 210px;
}

#content-mapa .cerrar {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  cursor: pointer;
}

#info-box-optimizado,
#info-box-optimizar {
  width: 100%;
  background-color: #efefef;
  padding: 5px 0px;
  position: absolute;
  top: 99px;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/*----------- TARGETA GT LISTA VISTA MAPA ------------*/
.tarjeta-gt-box {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
}

.tarjeta-gt {
  /* background-color: #e4e9fa; */
  border-radius: 2px;
  width: 100%;
  height: 175px;
}

.tarjeta-gt .check-box {
  width: 36px;
}

.tarjeta-gt .rayo-box {
  width: 40px;
}

.rayo-box img {
  border-radius: 5px;
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
}

.txt-small {
  text-align: center;
  color: #545454;
  font-size: 8.5px;
  line-height: 1.2;
  margin-bottom: 0px;
}

.tarjeta-gt .type-task-box {
  width: 25px;
}

.dot-type-task {
  position: absolute;
  top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  font-size: 10px;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fff;
}

.dot-type-task-1 {
  position: absolute;
  top: 50px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #fff;
}

.dot-type-task-2 {
  position: absolute;
  bottom: 60px;
  margin-left: 5px;
  margin-right: 5px;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #fff;
}

.dot-type-task-bottom {
  position: absolute;
  bottom: 55px;
  margin-left: 9.5px;
  margin-right: 9.5px;
  height: 6px;
  width: 6px;
  border-radius: 5px;
  background-color: #9999ff;
}

.line-type-task {
  height: 70px;
  position: absolute;
  top: 50px;
  margin-left: 11.5px;
}

.line-type-tasks {
  height: 60px;
  position: absolute;
  top: 55px;
  margin-left: 11.5px;
}

.line-type-more-tasks {
  height: 25px;
  position: absolute;
  top: 120px;
  margin-left: 11.5px;
}

.tarjeta-gt .info-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.tarjeta-gt .info-box p {
  font-size: 11px;
  line-height: 1.2;
  margin-bottom: 0px;
}

.tarjeta-gt .atrasado,
.tarjeta-gt .en-riesgo {
  position: absolute;
  bottom: 5px;
  right: 10px;
  margin-top: 3px;
  border-radius: 3px;
  height: 22px;
  font-size: 10px;
  line-height: 1.4;
  padding: 4px;
}

.tarjeta-gt .atrasado {
  background-color: rgba(250, 133, 138, 0.6);
  color: #c40812;
}

.tarjeta-gt .atrasado img,
.tarjeta-gt .en-riesgo img {
  height: 13px;
  width: 13px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.tarjeta-gt .en-riesgo {
  background-color: rgba(252, 213, 131, 0.8);
  color: #c78a05;
}

.tarjeta-gt .info-box .info-foot {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  bottom: 10px;
  color: #9f9f9f !important;
}

.tarjeta-gt .btn-estado {
  font-size: 10px;
  line-height: 1.2;
  color: #fff;
  position: absolute;
  padding: 5.5px;
  border-radius: 3px;
  text-align: center;
  top: 10px;
  right: 10px;
}

.tarjeta-gt .completed-box {
  position: absolute;
  top: 36px;
  right: 10px;
  font-size: 7.5px;
  line-height: 1.2;
  color: #545454;
}

.tarjeta-gt .detalle-box {
  width: 17px;
}

.tarjeta-gt .detalle-box img {
  width: 10px;
  height: 20px;
  cursor: pointer;
}

.order-id-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  font-family: "Roboto-Bold";
  top: 12px;
}

.customer-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  bottom: 10px;
  color: #9f9f9f !important;
  font-family: "Roboto-Bold";
}

.zone-box {
  width: calc(100% - 36px - 40px - 25px - 17px);
  position: absolute;
  bottom: 25px;
  color: #9f9f9f !important;
  font-family: "Roboto-Bold";
}
.mapa-google {
  height: 100%;
  width: 100%;
}

.bg-waring {
  background-color: #c78a05 !important;
}

.select-filter-estado:focus,
.select-filter-estado {
  height: 36.19px !important;
  border: 2px solid #1a11d1 !important;
  background-color: #ffffff;
  text-transform: capitalize !important;
}

.select-filter-estado.MuiButton-text {
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.select-filter-estado .MuiButton-endIcon {
  padding: 6px;
  margin-right: 0px !important;
  background-color: #1a11d1;
}

.select-search-options fieldset span {
  display: none !important;
}

.MuiOutlinedInput-root.Mui-focused.select-search-options .MuiOutlinedInput-notchedOutline,
.select-search-options fieldset {
  border: none !important;
  border-top: 2px solid #1a11d1 !important;
  border-bottom: 2px solid #1a11d1 !important;
  border-left: none !important;
  border-radius: 0px !important;
}

.MuiOutlinedInput-root.Mui-focused.input-search-options .MuiOutlinedInput-notchedOutline,
.input-search-options fieldset {
  border-top: 2px solid #1a11d1 !important;
  border-bottom: 2px solid #1a11d1 !important;
  border-left: 2px solid #1a11d1 !important;
  border-right: 1px solid #9f9f9f !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-search-options.MuiButton-root {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 9px !important;
  box-shadow: none !important;
}

.text-white .MuiFormControlLabel-label {
  color: #fff !important;
}

.switch-white .MuiSwitch-track {
  background-color: #f2f2f2 !important;
}

.btn-csv .MuiIconButton-edgeEnd {
  margin-right: 0px !important;
}
